$primary: #5156a1;
$secondary: #9594c9;
$enable-rounded: false;
$font-family-sans-serif: "Source Sans Pro";
$font-size-base: 1rem;
$btn-disabled-opacity: 0.325;
@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: bold;
  src: url("./assets/fonts/SourceSansPro-SemiBold.ttf");
}

body {
  background-color: #eee;
  background-image: url("./assets/bg.png");
  background-repeat: repeat-x;
  background-attachment: fixed;
  background-position: bottom;
}

@media print {
  body {
    background-image: none;
    background-color: unset;
  }
}

main {
  margin-top: 2em;
  margin-bottom: 2em;
}

h2 {
  color: $primary !important;
  font-weight: 900 !important;
  text-transform: uppercase;
}

h3 {
  font-weight: 400 !important;
  text-transform: uppercase;
}

.table td {
  vertical-align: middle;
}

.form-label.form-required:after {
  color: red;
  content: "*";
  font-weight: bold;
}

.progress {
  margin-top: 0.4rem;
}

.nav-link.active {
  font-weight: 700;
}

.dropdown-toggle::after {
  vertical-align: middle !important;
}

.mini-competence-card .card-body {
  padding: 0.275rem !important;
}
